<template>
  <div class="product-detail-view">
    <Breadcrumb :items="breadcrumb" />
    <template v-if="product.productId">
      <v-container>
        <ProductDetail
          v-if="product.productId"
          :product="product"
          :selectedImage="selectedImage"
          :key="'product_detail_' + key"
          @selectImage="selectImage"
          @goToCustomerVote="goToCustomerVote"
        />
      </v-container>
      <v-container>
        <ProductDescription
          v-if="product.productId"
          :panelOpen="0"
          :product="product"
          :key="'product_description_' + product.productId + '_key_' + key"
          :productMetadata="product.metaData"
          :ingredientList="product.ingredientList"
        />
        <!-- TODO: Frase per info prodotto -->
        <div
          class="product_info"
          @click="clicked"
          v-html="$t('productDescription.product_info')"
        ></div>
        <ProductListSlider
          v-if="product.variations && product.variations.length > 0"
          :productList="product.variations"
          :key="product.productId + '_3'"
          :paginationClass="'swiper-pagination-linea'"
          position="product_variations"
        >
          <h2>Altri prodotti della <strong>stessa linea:</strong></h2>
        </ProductListSlider>
      </v-container>
    </template>
    <not-found v-else />
    <!-- default layout 220 -> Altri clienti hanno acquistato: -->
    <category-block
      :target="category"
      :productId="product.productId"
      position="product1"
      class="category-block category-block-1"
    />

    <!-- default: crm layout 223 -> Ti potrebbe interessare... -->
    <category-block
      :target="category"
      :productId="product.productId"
      position="product2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.product-detail-view {
  // min-height: 1181px;
  img.normal {
    height: 360px;
    width: 360px;
  }
  img.image-mobile {
    height: 336px;
    width: 336px;
  }
  .thumb-wrapper {
    img {
      width: 80px;
      height: 80px;
    }
  }
  .back-button {
    &:hover {
      opacity: 0.5;
    }
  }
  .description-column {
    min-height: 296px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      min-height: 504px;
    }
  }
}
</style>
<script>
import get from "lodash/get";

import clickHandler from "~/mixins/clickHandler";

import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

// import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import { getFullName } from "@/customEbsn";

// import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";
import NotFound from "../NotFound.vue";
import productService from "@/commons/service/productService";

export default {
  name: "Product",
  mixins: [deliveryReactive, clickHandler],
  jsonld() {
    if (this.product && this.product.media) {
      let images = this.product.media.map(item => item.medium);
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.product.name,
        image: images,
        description: this.product.description,
        sku: this.product.codInt,
        mpn: this.product.codInt,
        brand: {
          "@type": "Brand",
          name: this.product.shortDescr
        },
        aggregateRating: {
          reviewCount: this.product.ratingSummary.total,
          ratingValue: this.product.ratingSummary.avg
          // bestRating: // ex: 5,0
        },
        offers: {
          "@type": "Offer",
          url: process.env.VUE_APP_EBSN_URL + "/" + this.product.slug,
          priceCurrency: "EUR",
          price: this.product.price ? this.product.price : 0,
          priceValidUntil: "2050-12-31", // NON HO UNA DATA VALIDA DI PREZZO
          itemCondition: "https://schema.org/NewCondition",
          availability:
            this.product.available >= 0
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
        }
      };
    }
  },
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1,
      layout20length: 0
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    Breadcrumb,
    ProductListSlider,
    NotFound
  },
  computed: {
    getFullName() {
      return getFullName(this.product);
    },
    breadcrumb() {
      return this.$store.getters["category/categoryPathById"](
        this.product.categoryId
      );
    },
    category() {
      return this.$store.getters["category/lookupCategory"](
        this.product.categoryId
      );
    }
  },
  methods: {
    async setProduct(product) {
      // var data = await ProductService.getProductBySlug(this.slug);
      if (product) {
        this.product = product;
        // if (this.product.categoryId) {
        //   this.setCategory(this.product.categoryId);
        // }
        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
        analyticsService.viewProductDetail(this.product);
      } else {
        this.showNoProductCard = true;
      }
    },
    loaded(productsFound) {
      this.layout20length = productsFound;
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      //this.$store.dispatch("category/resetFilters");
      //let slug = split(this.category.slug, "@")[0];
      //await this.setCategory(slug);
      let product = await productService.getProductBySlug(this.slug);
      this.getProduct(product);
      this.key += 1;
    },
    goToCustomerVote() {
      // goToCustomerVote;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  created() {
    let _this = this;
    _this.slug = _this.$route.params.slug;
    this.setProduct(_this.$route.params.product);
    // _this.product = _this.$route.params.product;
    // if (_this.product) {
    //   _this.showNoProductCard = false;
    //   if (_this.product.categoryId) {
    //     _this.setCategory(_this.product.categoryId);
    //   }
    // }
    // analyticsService.viewProductDetail(this.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        this.setProduct(this.$route.params.product);
        // this.product = this.$route.params.product;
        // if (this.product) {
        //   this.showNoProductCard = false;
        // }
        // analyticsService.viewProductDetail(this.product);
      }
    }
  },
  metaInfo() {
    if (this.product)
      return {
        title: this.$route.meta.gtm,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: get(
              this.product,
              "metaData.product_seo.SEO_DESCRIPTION",
              this.getFullName +
                " online. " +
                this.category.name +
                " in offerta e promozione su Tigros con consegna in azienda e ritiro in negozio."
            )
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: get(this.product, "metaData.product_seo.SEO_KEYWORDS")
          },
          // Open Graph Metas
          {
            property: "og:locale",
            content: "it_IT"
          },
          {
            property: "og:title",
            content: get(
              this.product,
              "metaData.product_seo.SEO_TITLE",
              this.getFullName
            )
          },
          {
            property: "og:type",
            content: "product"
          },
          {
            property: "og:url",
            content: location.href
          },
          {
            property: "og:image",
            content: get(
              this.product,
              "media[0].large",
              window.location.host + global.config.socialLogo ||
                "/logo/social-logo.png"
            )
          },
          {
            property: "og:image:alt",
            content: get(
              this.product,
              "metaData.product_seo.SEO_DESCRIPTION",
              this.getFullName +
                " online. " +
                this.category.name +
                " in offerta e promozione su Tigros con consegna in azienda e ritiro in negozio."
            )
          },
          {
            property: "og:site_name",
            content: "Tigros"
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: get(
              this.product,
              "metaData.product_seo.SEO_DESCRIPTION",
              this.getFullName +
                " online. " +
                this.category.name +
                " in offerta e promozione su Tigros con consegna in azienda e ritiro in negozio."
            )
          },
          {
            name: "twitter:image",
            content: get(
              this.product,
              "media[0].large",
              window.location.host + global.config.socialLogo ||
                "/logo/social-logo.png"
            )
          },
          {
            name: "twitter:url",
            content: location.hrefπgetFullName
          }
        ]
      };
  }
};
</script>
